<template>
    <div class="check-out">
        <el-dialog v-model="show_obligo" width="400px">
            <div class="client-obligo">
                <div class="title-ob">
                    <p>גבייה - לקוחות</p>
                </div>
                <div class="obligo-line">
                    <p>חוב פתוח:</p>
                    <p>{{client_obligo.debt}} ₪</p>
                </div>
                <div class="obligo-line">
                    <p>אובליגו פנוי:</p>
                    <p>{{client_obligo.obligo_left}} ₪</p>
                </div>
                <div class="obligo-line">
                    <p>תקרת אובליגו:</p>
                    <p>{{client_obligo.max_obligo}} ₪</p>
                </div>
                <div class="title-ob">
                    <p>פירוט חש. לתשלום</p>
                </div>
                <div class="obligo-orders">
                    <template v-for="order in client_obligo.orders_to_pay" :key="order.IVNUM">
                        <div class="obligo-order">
                            <div class="choose-obligo-order" @click="order.choose = !order.choose" :style="check_choose(order.choose)"></div>
                            <p>סוג: {{order.DETAILS}}</p>
                            <p>ת.חשבונית: {{new Date(order.BALDATE).toLocaleDateString('he')}}</p>
                            <p>ת.אחרון לתשלום: {{new Date(order.FNCDATE).toLocaleDateString('he')}}</p>
                            <p>סכום: {{order.SUM1}} ש"ח</p>
                        </div>
                    </template>
                </div>
                <div class="total-orders">
                    <p>סה"כ: {{obligo_orders_chosen}}</p>
                </div>
            </div>
        </el-dialog>

        <div class="sending" v-if="sending_order">
            <p>שולח הזמנה, אנא המתן...</p>
        </div>
        <div class="client-info">
            <div class="client-box">
                <div class="user-image">
                    <img :src="user.profile_image" alt="">
                </div>
                <div class="user-data">
                    <div class="order-date">
                        <p style="font-size: 13px;">{{new Date().toLocaleDateString()}}</p>
                    </div>
                    <p style="color:var(--yellow)">{{user.name}}</p>
                    <p>{{user.company}}</p>
                    <p>{{user.email}}</p>
                    <p>{{user.phone}}</p>
                </div>
            </div>
        </div>
        <div class="summery">
            <div class="order-summery">
                <p class="summery-title" style="width:100%; text-align:center;">סיכום הזמנה</p>
                <p v-if="user.client">מספר לקוח: {{user.client}}</p>
                <p v-else>מספר לקוח: משתמש פרטי</p>
                <p>תאריך הזמנה: {{new Date().toLocaleString('he')}}</p>
                <p style="margin-top: 10px; font-size: 18px; widrh:100%; background: var(--secondary);color:var(--yellow)">כתובת למשלוח:</p>
                <p :class="check_adress(adress.city)">עיר: {{adress.city}}</p>
                <p :class="check_adress(adress.adress)">כתובת: {{adress.adress}}</p>
                <p :class="check_adress(adress.floor)">קומה: {{adress.floor}}</p>
                <p :class="check_adress(adress.postal_code)">מיקוד: {{adress.postal_code}}</p>
                <el-button type="primary" class="update-adress-btn" @click="update_adress(user)">עדכן כתובת</el-button>
            </div>
        </div>
        <div class="order-table">
            <table id="table">
                <tr>
                    <th>תמונה</th>
                    <th>מקט</th>
                    <th>פריט</th>
                    <th v-if="see_prices">מחיר*</th>
                    <th>כמות</th>
                    <th>אריזות</th>
                    <th>הערה</th>
                </tr>
                <template v-for="item in cart.items" :key="item.makat">
                    <tr>
                        <td><img :src="item.image" alt=""></td>
                        <td>{{item.makat}}</td>
                        <td>{{item.name}}</td>
                        <td v-if="(!item.discount || item.discount == 0) && !item.sale_price && see_prices">{{item.basePrice}}</td>
                        <td v-if="item.discount && !item.sale_price && see_prices">
                            <p style="width:100%; text-align:center; color:var(--success)">הנחה</p>
                            {{(Number(item.basePrice) * (1 - Number(item.discount)/100)).toFixed(2)}}
                        </td>
                        <td v-if="item.sale_price && !item.discount && see_prices">
                            <p style="width:100%; text-align:center; color:var(--danger)">מבצע</p>
                            {{Number(item.sale_price).toFixed(2)}}   
                        </td>
                        <td>{{item.order_amount}}</td>
                        <td>{{Number(item.order_amount / item.boxSize).toFixed(2)}}</td>
                        <td><el-button type="primary" @click="add_note_to_item(item)" v-if="Number(user.agent) > 0">הערה</el-button></td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="finilize">
            <div class="items-summery">
                <p style="margin-top: 5px; margin-bottom: 5px;font-size: 18px; widrh:100%; background: var(--secondary); color:var(--yellow)">סיכום פריטים:</p>
                <p>סה"כ פריטים בהזמנה: {{cart.items.length}}</p>
                <p>סה"כ יח' בהזמנה: {{total_item_count.count}}</p>
                <p>משקל כולל: {{total_item_count.weight}} ק"ג</p>
                <p style="margin-top: 5px; margin-bottom: 5px;font-size: 18px; widrh:100%; background: var(--secondary);color:var(--yellow)" v-if="see_prices">סיכום עלויות:</p>
                <p v-if="see_prices">עלות סה"כ לפני מע"מ ופקדון: {{total_item_count.cost.toFixed(2)}} ₪</p>
                <p v-if="see_prices">מע"מ (17%): {{total_item_count.vat.toFixed(2)}} ₪</p>
                <p v-if="see_prices">פיקדון (כולל מע"מ): {{total_item_count.pikadon.toFixed(2)}} ₪</p>
                <p class="total-pay" v-if="see_prices">סה"כ לתשלום: {{total_item_count.total_pay.toFixed(2)}} ₪</p>
            </div>
        </div>
        <div class="end-cart">
            <div class="cart-actions">
                <el-button type="success" @click="go_to_pay" style="margin-left: 10px;" v-if="user.agent == 0">עבור לתשלום</el-button>
                <el-button type="success" @click="init_send_order" style="margin-left: 10px;" v-if="Number(user.agent) > 0">שדר הזמנה</el-button>
                <el-button type="primary" @click="show_obligo =!show_obligo" style="margin-left: 10px;" v-if="Number(user.agent) > 0">גבייה</el-button>
                <el-button type="primary" @click="print_order" v-if="user.agent == 0">שלח להדפסה</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import store from '../../store'
import {update_adress} from '../../Methods/Users';
import Swal from 'sweetalert2';
import { projectFunctions } from '../../firebase/config';
import { slide_pop_successs } from '../../Methods/Msgs';
import router from '../../router';

export default {
setup(){

    const sending_order = ref(false);
    const client_obligo = ref(null);

    const user = ref(computed(() => {
        return store.getters.user
    }))

    const cart = ref(computed(() => {
        return store.getters.client_cart
    }))

    const adress = ref(computed(() => {
        let _address = {
            city: '',
            adress: '',
            postal_code: '',
            floor: ''
        }
        if(!user.value.client){
            if(!user.value.city){
            _address.city = 'טרם עודכן'
            _address.adress = 'טרם עודכן'
            _address.postal_code = 'טרם עודכן'
            _address.floor = 'טרם עודכן'
            update_adress(user.value);
            }
            else{
            _address.city = user.value.city
            _address.adress = user.value.adress
            _address.postal_code = user.value.postal_code
            _address.floor = user.value.floor
            }
        }else{
            // if user is a logistics client -> get data from clients doc
            if(user.value.client_data){
                _address.city = user.value.client_data.city
                _address.adress = user.value.client_data.adress
                _address.postal_code = '00000'
                _address.floor = '0'          
            }else{
                _address.city = 'טרם עודכן'
                _address.adress = 'טרם עודכן'
                _address.postal_code = 'טרם עודכן'
                _address.floor = 'טרם עודכן'
                update_adress(user.value);
            }
        }
        return _address
    }))

    const check_adress =(value) => {
        if(value && value.includes('טרם')){
            return 'danger'
        }else{
            return ''
        }
    }

    const total_item_count = ref(computed(() => {
        let total = {
            count: 0,
            weight: 0,
            cost: 0,
            pikadon: 0,
            total_pay: 0,
            vat: 0
        }
        cart.value.items.forEach(item => {
            total.count += Number(item.order_amount)
            if(item.weight && Number(item.weight) > 1){
                total.weight += Number(item.order_amount) * Number(item.weight / 1000)
            }
            if(item.discount && !item.sale_price){
                total.cost += (Number(item.basePrice) * (1- Number(item.discount)/100)) * Number(item.order_amount)
            }
            if(!item.discount && item.sale_price){
                total.cost += Number(item.sale_price) * Number(item.order_amount)
            }
            if((!item.discount || item.discount == 0) && !item.sale_price){
                total.cost += Number(item.basePrice) * Number(item.order_amount)
            }
            if(item.pikadon && item.pikadon >= 1){
                total.pikadon += Number(item.pikadon) * 0.30 * item.order_amount
            }
        })
        total.vat = total.cost * 1.17 - total.cost; 
        total.total_pay = total.vat + total.cost + total.pikadon
        return total
    }))
    
    const go_to_pay = () => {

    }

    const print_order = () => {

    }

    const see_prices = ref(computed(() => {
        return store.getters.client_cart.prices
    }))

    const init_send_order = async () => {
        if(Number(client_obligo.value.obligo_left) < Number(store.state.client_cart.total_cost)){
            Swal.fire({
                icon: 'error',
                title: 'אובליגו',
                text: 'ההזמנה חוצה את תקרת האובליגו, אנא צור קשר עם המשרדים.'
            })
            return
        }
        console.log(user.value);
        console.log(cart.value);
        store.state.client_cart.client = user.value.client
        store.state.client_cart.assortment = user.value.assortment
        if(user.value.cords && user.value.cords.lng){
            store.state.client_cart.GPSX = user.value.cords.lng
            store.state.client_cart.GPSY = user.value.cords.lat
        }else{
            // user has no GPS on!
        }
        await agent_order_options()
    }

    const add_note_to_item = async (item) => {
        await Swal.fire({
            icon: 'question',
            title: 'הערה למשרדים',
            text: 'הכנס הערה להנהלת חשבונות במידת הצורך, השאר ריק אם אין.',
            input: 'textarea',
            inputPlaceholder: item.note_office,
            confirmButtonText: 'המשך'
        }).then((res) => {
            if(res.isConfirmed){
                if(res.value && res.value.length > 0){
                    item.note_office = res.value
                }
            }
        })
        await Swal.fire({
            icon: 'question',
            title: 'הערה ללוגיסטיקה',
            text: 'הכנס הערה ללוגיסטיקה במידת הצורך, השאר ריק אם אין.',
            input: 'textarea',
            inputPlaceholder: item.note_logistics,
            confirmButtonText: 'המשך'
        }).then((res) => {
            if(res.isConfirmed){
                if(res.value && res.value.length > 0){
                    item.note_logistics = res.value
                }
            }
        })
    }

    const agent_order_options = async () => {
        await Swal.fire({
            icon: 'question',
            title: 'פרטים להזמנה',
            text: 'הכנס הערות להזמנה, במידה ואין - השאר ריק',
            input: 'textarea',
            inputPlaceholder: store.state.client_cart.notes,
            confirmButtonText: 'המשך'
        }).then((res) => {
            if(res.isConfirmed){
                if(res.value && res.value.length > 0){
                    store.state.client_cart.notes = res.value
                }
            }
        })
        await Swal.fire({
            icon: 'success',
            title: 'שידור הזמנה',
            text: 'האם לשדר את ההזמנה? במידה ונזכרת בשינויים, לחץ חזור להזמנה.',
            confirmButtonText: 'שדר',
            cancelButtonText: 'חזור להזמנה',
            showCancelButton: true
        }).then(async (res) => {
            if(res.isConfirmed){
                sending_order.value = true;
                console.log('Sending order!');
                const send_order = await projectFunctions.httpsCallable("send_new_agent_order")({cart: store.getters.client_cart, user:user.value})
                if(send_order.data){
                    sending_order.value = false;
                    slide_pop_successs("ההזמנה שודרה בהצלחה!")
                    router.replace('/');
                    store.dispatch("clear_cart");
                }
            }
        })
    }


    const show_obligo = ref(false);
    const check_choose = (state) => {
        if(state){
            return 'background: var(--success);'
        }else{
            return 'background: white;'
        }
    }
    const get_obligo = async () => {
        const obligo = await projectFunctions.httpsCallable("get_client_obligo")({client: user.value.client})
        if(obligo && obligo.data){
            client_obligo.value = {
                max_obligo : obligo.data.CUSTOBLIGO_SUBFORM[0].MAX_OBLIGO,
                debt : obligo.data.CUSTOBLIGO_SUBFORM[0].OBLIGO_ENV,
                obligo_left: obligo.data.CUSTOBLIGO_SUBFORM[0].OBLIGO_DIFF,
                pay_days: obligo.data.PAYDES,
                fetched: true,
                orders_to_pay:  obligo.data.CUSTOBLIGO_SUBFORM[0].OBLIGO_FNCITEMS_SUBFORM
            }
            client_obligo.value.orders_to_pay.forEach(order => {
                order.choose = false;
            })
        }else{
            client_obligo.value = {
                fetched: false
            }
        }
        console.log(client_obligo.value);
    }

    const obligo_orders_chosen = ref(computed(() => {
        let total = 0;
        if(client_obligo.value && client_obligo.value.orders_to_pay){
            client_obligo.value.orders_to_pay.forEach(order => {
                if(order.choose){
                    total += order.SUM1
                }
            })
        }
        return total
    }))

    onMounted(() => {
        get_obligo();
    })

    return{
        cart, user, adress, update_adress, check_adress,total_item_count,
        go_to_pay, print_order, init_send_order, add_note_to_item, see_prices,
        sending_order, show_obligo, client_obligo, obligo_orders_chosen, check_choose
    }
}
}
</script>

<style scoped>
.sending{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: var(--main);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
    font-size: 21px;
}
.check-out{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.client-info{
    width: 100%;
    height: 120px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: unset;
}
.client-box{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 98%;
    max-width: 600px;
    padding: 5px;
    background: var(--secondary);
}
.order-table{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    overflow-y: auto;
}
.summery{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}
.finilize{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}
.end-cart{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    display: flex;
    justify-content: center; 
}
#table {
    border-collapse: collapse;
    width: 100%;
    max-width: 600px;
    flex-shrink: 0;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
#table tr img{
    max-width: 30px;
    max-height: 100%;
    border-radius: 5px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}
#table tr:hover {background-color: #ddd;}
#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.user-image{
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.815);
}
.user-data{
    position: relative;
    padding-right: 5px;
    width: calc(100% - 100px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.order-date{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    max-width: 100px;
}
.user-data p {
    width: 100%;
    line-height: 18px;
    font-size: 18px;
    margin-bottom: 5px;
    color: white;
    text-shadow: 0 0 3px rgba(15, 15, 15, 0.495);
    text-align: center;
}
.order-summery{
    position: relative;
    width: 100%;
    max-width: 600px;
    flex-shrink: 0;
    height: fit-content;
    margin-bottom: 10px;
    color: white;
}
.items-summery{
    position: relative;
    width: 100%;
    max-width: 600px;
    flex-shrink: 0;
    height: fit-content;
    margin-bottom: 10px;
    color: white;
}
.summery-title{
    width: 100%;
    font-size: 24px;
    text-decoration: underline;
    margin-bottom: 5px;
}
.update-adress-btn{
    position: absolute;
    top: 0;
    left: 5px;
    top: 115px;
}
.danger{
    color: var(--danger);
}
.cart-actions{
    position: relative;
    width: 100%;
    max-width: 600px;
    flex-shrink: 0;
    height: 50px;
    margin-bottom: 10px;
    color: white;
}
.total-pay{
    font-size: 24px;
    margin: 5px 0 5px 0;
    background:  var(--success);
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.76);
    text-align: center;
    padding: 5px 0 5px 0;
}
.client-obligo{
    width: 100%;
    height: 470px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.obligo-orders{
    width: 100%;
    height: 60%;
    overflow-y: auto;
}
.obligo-order{
    width: 100%;
    height: fit-content;
    margin-bottom: 5px;
    flex-shrink: 0;
    background-color: var(--secondary);
    color: whitesmoke;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    position: relative;
}
.title-ob{
    width: 100%;
    height: 30px;
    font-size: 20px;
    background-color: var(--success);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    text-shadow: 0 0 3px black;
    margin-bottom: 5px;
}
.obligo-line{
    width: 100%;
    padding: 5px;
    height: fit-content;
    flex-shrink: 0;
    font-size: 24px;
    color: black;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
}
.obligo-line :first-child{
    width: 50%;
}
.choose-obligo-order{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: black;
    cursor: pointer;
}
.total-orders{
    width: 100%;
    height: 30px;
    text-align: center;
    background-color: var(--warning);
    margin-top: 5px;
    color: black;
    font-size: 24px;
}
</style>